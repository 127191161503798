<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="mnotify-card">
            <div
              class="mnotify-card-header d-flex justify-content-between align-items-center"
            >
              <h3 class="mnotify-card-header-text">API v.2.0</h3>
            </div>
            <div class="">
              <div class="campaigne-card-inner">
                <div class="row">
                  <div class="col-md-12">
                    <button class="create_api_key" @click="showApiKey = true">
                      Create API Key
                    </button>
                  </div>
                  <el-dialog
                    class="show-api-container"
                    v-if="showApiKey"
                    title="Create API Key v.2.0"
                    :visible.sync="showApiKey"
                  >
                    <el-form
                      :model="formData"
                      :rules="rules"
                      ref="ruleForm"
                      class="main-form"
                    >
                      <div class="form_input_container my-5">
                        <el-form-item prop="project_name" label="Project Name">
                          <el-input
                            type="text"
                            placeholder="Enter name of project associated with this API key."
                            v-model="formData.project_name"
                            autocomplete="false"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div class="form_input_container">
                        <el-form-item prop="usage_limit" label="Usage Limit">
                          <el-input
                            type="text"
                            placeholder="Please enter usage limit."
                            v-model="formData.usage_limit"
                            autocomplete="false"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </el-form>
                    <div class="row">
                      <div class="col-md-">
                        <Alert
                          v-if="createError"
                          :message="createError"
                          :type="type"
                        />
                      </div>
                      <div class="col-md-12 _buttons">
                        <button class="_cancel" @click="showApiKey = false">
                          Cancel
                        </button>
                        <el-button
                          class="create_api_key"
                          :loading="loading"
                          @click="submit('ruleForm')"
                        >
                          Create API Key
                        </el-button>
                      </div>
                    </div>
                  </el-dialog>
                  <div class="__reporting__table" v-if="apiKeys.length">
                    <table class="__mnotify__table">
                      <thead class="__mnotify__table__header">
                        <tr>
                          <th
                            style="width: 15%"
                            class="__mnotify__table__header_first"
                          >
                            Project Name
                          </th>
                          <th style="width: 20%">Key</th>
                          <th style="width: 15%">Date Created</th>
                          <th style="width: 15%">Status</th>
                          <th style="width: 15%">Usage Limit</th>
                          <th style="width: 20%">Actions</th>
                        </tr>
                      </thead>
                      <tbody class="__mnotify__table__body">
                        <tr
                          class="__table__with__border"
                          v-for="(j, key) in apiKeys"
                          :key="key"
                        >
                          <td
                            class="mnotify__table__first__item"
                            style="cursor: pointer"
                          >
                            {{ j.project_name }}
                          </td>
                          <td>
                            {{ j.masked_key }}
                          </td>
                          <td>
                            {{ moment(j.created_at).format("llll") }}
                          </td>
                          <td :class="[j.status == 0 ? 'disabled' : 'enabled']">
                            {{ j.status == 0 ? "disabled" : "enabled" }}
                          </td>
                          <td>
                            {{ j.usage_limit }}
                          </td>
                          <td>
                            <div class="_stat">
                              <div
                                class="known_actions"
                                style="display: flex; gap: 0.5rem"
                              >
                                <button
                                  class="delete"
                                  @click="addLimit({ key: key, data: j })"
                                >
                                  <img
                                    width="20px"
                                    height="20px"
                                    src="@/assets/Edit.svg"
                                    alt="edit icon"
                                  />
                                </button>
                                <button
                                  class="delete"
                                  @click="opendModal({ message: j, key: key })"
                                >
                                  <img
                                    width="20px"
                                    height="20px"
                                    src="@/assets/__delete.svg"
                                    alt="bin icon for delete"
                                  />
                                </button>
                              </div>
                              <el-switch
                                :value="j.status == 0 ? false : true"
                                @change="handleStatus({ key: key, data: j })"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                              >
                              </el-switch>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12 __api_text">
                    <p>
                      Visit the
                      <a
                        class="_visit"
                        target="_blank"
                        href="https://readthedocs.mnotify.com/"
                        >documentation page</a
                      >
                      to read about the API v.2.0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <messageModal
      ref="messageModal"
      :payload="dataPayload"
      :from="from"
      @deleteSuccess="deleteSuccess"
    />
    <!-- ADD LIMIT MODAL -->
    <div
      class="modal fade"
      id="deletecontact"
      tabindex="-1"
      role="dialog"
      aria-labelledby="contact"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content d-flex align-items-center" v-if="success">
          <successMessage :message="success" />
          <button @click="hideModal" class="__close__success__modal">
            Close
          </button>
        </div>
        <div class="modal-content pb-3" v-if="!success">
          <div class="modal-body m-0 p-0">
            <div class="modal-header pt-4 pb-2">
              <h3 class="contact_detail_header">Update Usage Limit</h3>
            </div>
            <div class="mt-3">
              <div class="col-md-12 mb-2">
                <el-form
                  :model="formData"
                  ref="ruleForm"
                  :rules="rules"
                  @submit.prevent.native="checkEnter"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form_input_container">
                        <el-form-item
                          label="Usage Limit"
                          prop="limit"
                          required=""
                        >
                          <el-input
                            type="text"
                            autocomplete="off"
                            placeholder="Enter usage limit"
                            v-model="formData.limit"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
              <div class="col-md-12">
                <Alert v-if="error" :message="error" :type="type" />
              </div>
            </div>
            <div
              class="modal__footer__btns d-flex justify-content-between mx-3"
            >
              <button style="width: 123px" class="cancel" @click="hideModal">
                Cancel
              </button>
              <el-button
                style="width: 123px"
                type="primary"
                :loading="loading"
                id="cancelinput"
                @click="save('ruleForm')"
                >Add Limit</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ENABLE STATUS -->
    <div
      class="modal fade"
      id="togglestatus"
      tabindex="-1"
      role="dialog"
      aria-labelledby="contact"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content d-flex align-items-center" v-if="success">
          <successMessage :message="success" />
          <button @click="hideToggleModal" class="__close__success__modal">
            Close
          </button>
        </div>
        <div class="modal-content pb-3" v-if="!success">
          <div class="modal-body m-0 p-0">
            <div class="mt-3">
              <div class="col-md-12 mb-2 mt-5 mb-5">
                <p class="text-center">
                  {{
                    selectedStatus == 0
                      ? "Are you sure you want to enable API?"
                      : "Are you sure you want to disable API ? "
                  }}
                </p>
              </div>
              <div class="col-md-12">
                <Alert v-if="error" :message="toggleStatusError" :type="type" />
              </div>
            </div>
            <div
              class="modal__footer__btns d-flex justify-content-between mx-3"
            >
              <button
                style="width: 123px"
                class="cancel"
                @click="hideToggleModal"
              >
                Cancel
              </button>
              <el-button
                style="width: 123px"
                type="primary"
                :loading="toggleLoading"
                id="cancelinput"
                @click="toggleStatus"
              >
                {{ selectedStatus == 0 ? "Enable" : "Disable" }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import messageModal from "../messages/messageModal.vue";
  import moment from "moment";
  export default {
    name: "api-v2",
    components: {
      messageModal,
    },
    data() {
      return {
        showApiKey: false,
        loading: false,
        createError: "",
        success: "",
        type: "alert-danger",
        error: "",
        formData: {
          project_name: "",
          usage_limit: "",
          limit: "",
        },
        from: "",
        dataPayload: {},
        apiKeys: [],
        enabled: "",
        selecctedId: "",
        selectedKey: "",
        selectedStatus: 0,
        toggleStatusError: "",
        toggleLoading: false,
        rules: {
          limit: [
            {
              required: true,
              message: "Please enter usage limit.",
              trigger: ["change", "blur"],
            },
          ],
          usage_limit: [
            {
              required: true,
              message: "Please enter usage limit.",
              trigger: ["change", "blur"],
            },
          ],
          project_name: [
            {
              required: true,
              message: "Please enter project name.",
              trigger: ["change", "blur"],
            },
          ],
        },
      };
    },
    methods: {
      hideToggleModal() {
        this.formData.limit = "";
        this.success = "";
        $("#togglestatus").modal("hide");
      },
      toggleStatus() {
        this.toggleLoading = true;
        this.toggleStatusError = "";
        if (this.selectedStatus == 0) {
          return this.enabledStatus();
        }
        return this.disabledStatus();
      },
      enabledStatus() {
        store
          .dispatch("developer/enableApiV2", this.selecctedId)
          .then((data) => {
            this.apiKeys[this.selectedKey].status = 1;
            this.apiKeys = [...this.apiKeys];
            this.success = data.message;
          })
          .catch((err) => {
            if (err.response.data == undefined) {
              this.toggleStatusError = "An error occured try again.";
            }
            if (err.response && err.response.status === 404) {
              this.toggleStatusError = err.response.data.data;
            }
          })
          .finally(() => (this.toggleLoading = false));
      },
      disabledStatus() {
        store
          .dispatch("developer/disableApiV2", this.selecctedId)
          .then((data) => {
            this.apiKeys[this.selectedKey].status = 0;
            this.apiKeys = [...this.apiKeys];
            this.success = data.message;
          })
          .catch((err) => {
            if (err.response.data == undefined) {
              this.toggleStatusError = "An error occured try again.";
            }
            if (err.response && err.response.status === 404) {
              this.toggleStatusError = err.response.data.data;
            }
          })
          .finally(() => (this.toggleLoading = false));
      },
      handleStatus({ key, data }) {
        this.success = false;
        this.selectedKey = key;
        this.selectedStatus = data.status;
        this.selecctedId = data.id;
        $("#togglestatus").modal("show");
      },
      addLimit({ key, data }) {
        this.selectedKey = key;
        this.selecctedId = data.id;
        this.formData.limit = "";
        this.success = "";
        $("#deletecontact").modal("show");
      },
      hideModal() {
        this.formData.limit = "";
        this.success = "";
        $("#deletecontact").modal("hide");
      },
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.error = "";
            store
              .dispatch("developer/addLimitApiV2", {
                id: this.selecctedId,
                body: this.formData,
              })
              .then((data) => {
                this.apiKeys[this.selectedKey].usage_limit =
                  this.formData.limit;
                this.apiKeys = [...this.apiKeys];
                this.success = data.message;
              })
              .catch((err) => {
                if (err.response.data == undefined) {
                  this.error = "An error occured try again.";
                }
                if (err.response && err.response.status === 404) {
                  this.error = err.response.data.data;
                }
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      deleteSuccess(data) {
        this.apiKeys.splice(this.dataPayload.selectedKey, 1);
      },
      opendModal({ message, key }) {
        this.dataPayload = {
          currentModal: "delete",
          selectedKey: key,
          selectedMessage: message,
          deleteType: "_deleteApiV2Key",
          deleteMessage: "This will remove the Api Key permanently.",
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "delete",
        });
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      async getApiV2() {
        const data = await store.dispatch("developer/getApiV2");
        this.apiKeys = data;
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.createError = "";
            store
              .dispatch("developer/createApiV2", this.formData)
              .then((data) => {
                this.showApiKey = false;
                this.getApiV2();
                this.$alert(
                  `Your API key is "${data.data.key}" and has been automatically downloaded. Save it somewhere safe. You won't be able to see it again.`,
                  "API Key",
                  {
                    confirmButtonText: "OK",
                  }
                );
              })
              .catch((err) => {
                if (err.response.data == undefined) {
                  this.createError = "An error occured try again.";
                }
                if (err.response && err.response.status === 404) {
                  this.createError = err.response.data.data;
                }
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
    mounted() {
      this.getApiV2();
    },
  };
</script>

<style lang="scss" scoped>
  ._stat {
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
  }

  .disabled {
    color: #ff4949;
  }

  .enabled {
    color: #13ce66;
  }

  .delete {
    outline: none;
    border: none;
    background: transparent;
  }

  ._buttons {
    display: flex;
    justify-content: flex-end;
  }

  .create_api_key,
  ._cancel {
    outline: none;
    border: none;
    margin-bottom: 2em;
    border-radius: 5px;
    margin-top: 1em;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #ffffff;
    padding: 0.7em 2em;
    text-transform: uppercase;
    float: right;
  }

  .create_api_key {
    background: #f7921c;
    color: #fff;
  }

  ._cancel {
    margin-right: 1em;
    padding: 0.7em 2em;
  }

  .__api_text {
    margin-bottom: 4em;

    p {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #575761;
      margin-bottom: 0px;
    }
  }

  ._visit {
    color: #f7921c;

    &:hover {
      color: #f7921c;
    }
  }

  table {
    overflow-y: scroll;
  }

  .show-api-container {
    position: fixed;
    width: 100vmax;
    height: 100vmax;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 100vmin 0 0 100vmax rgba(0, 0, 0, 0.5);
  }

  .show-api-create {
    background-color: #ffffff;
    padding: 1rem;
    width: fit-content;
    background-color: red;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.8s ease-in;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  table {
    overflow-y: scroll;
  }
</style>
